define("moment/lib", ["exports", "ember-get-config"], function (_exports, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* globals self */

  var moment;
  var localeOutputPath = _emberGetConfig.default.moment && _emberGetConfig.default.moment.includeTimezone;
  if (typeof self.FastBoot === 'undefined') {
    moment = self.moment;
  } else if (localeOutputPath) {
    moment = self.FastBoot.require('moment-timezone');
  } else {
    moment = self.FastBoot.require('moment');
  }
  var _default = _exports.default = moment;
});