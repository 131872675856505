define("ember-concurrency/-buffer-policy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.enqueueTasksPolicy = _exports.dropQueuedTasksPolicy = _exports.dropButKeepLatestPolicy = _exports.cancelOngoingTasksPolicy = void 0;
  function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
  function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  var saturateActiveQueue = function saturateActiveQueue(scheduler) {
    while (scheduler.activeTaskInstances.length < scheduler.maxConcurrency) {
      var taskInstance = scheduler.queuedTaskInstances.shift();
      if (!taskInstance) {
        break;
      }
      scheduler.activeTaskInstances.push(taskInstance);
    }
  };
  function numPerformSlots(scheduler) {
    return scheduler.maxConcurrency - scheduler.queuedTaskInstances.length - scheduler.activeTaskInstances.length;
  }
  var enqueueTasksPolicy = _exports.enqueueTasksPolicy = {
    requiresUnboundedConcurrency: true,
    schedule: function schedule(scheduler) {
      // [a,b,_] [c,d,e,f] becomes
      // [a,b,c] [d,e,f]
      saturateActiveQueue(scheduler);
    },
    getNextPerformStatus: function getNextPerformStatus(scheduler) {
      return numPerformSlots(scheduler) > 0 ? 'succeed' : 'enqueue';
    }
  };
  var dropQueuedTasksPolicy = _exports.dropQueuedTasksPolicy = {
    cancelReason: "it belongs to a 'drop' Task that was already running",
    schedule: function schedule(scheduler) {
      // [a,b,_] [c,d,e,f] becomes
      // [a,b,c] []
      saturateActiveQueue(scheduler);
      scheduler.spliceTaskInstances(this.cancelReason, scheduler.queuedTaskInstances, 0, scheduler.queuedTaskInstances.length);
    },
    getNextPerformStatus: function getNextPerformStatus(scheduler) {
      return numPerformSlots(scheduler) > 0 ? 'succeed' : 'drop';
    }
  };
  var cancelOngoingTasksPolicy = _exports.cancelOngoingTasksPolicy = {
    cancelReason: "it belongs to a 'restartable' Task that was .perform()ed again",
    schedule: function schedule(scheduler) {
      // [a,b,_] [c,d,e,f] becomes
      // [d,e,f] []
      var activeTaskInstances = scheduler.activeTaskInstances;
      var queuedTaskInstances = scheduler.queuedTaskInstances;
      activeTaskInstances.push.apply(activeTaskInstances, _toConsumableArray(queuedTaskInstances));
      queuedTaskInstances.length = 0;
      var numToShift = Math.max(0, activeTaskInstances.length - scheduler.maxConcurrency);
      scheduler.spliceTaskInstances(this.cancelReason, activeTaskInstances, 0, numToShift);
    },
    getNextPerformStatus: function getNextPerformStatus(scheduler) {
      return numPerformSlots(scheduler) > 0 ? 'succeed' : 'cancel_previous';
    }
  };
  var dropButKeepLatestPolicy = _exports.dropButKeepLatestPolicy = {
    cancelReason: "it belongs to a 'keepLatest' Task that was already running",
    schedule: function schedule(scheduler) {
      // [a,b,_] [c,d,e,f] becomes
      // [d,e,f] []
      saturateActiveQueue(scheduler);
      scheduler.spliceTaskInstances(this.cancelReason, scheduler.queuedTaskInstances, 0, scheduler.queuedTaskInstances.length - 1);
    }
  };
});