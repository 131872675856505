define("accounting/parse", ["exports", "accounting/unformat"], function (_exports, _unformat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Alias of unformat
   *
   * @method parse
   * @for accounting
   */
  var _default = _exports.default = _unformat.default;
});