define('ember-i18n/helper', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var get = Ember.get,
      inject = Ember.inject,
      Helper = Ember.Helper,
      EmberObject = Ember.Object,
      observer = Ember.observer;


  function mergedContext(objectContext, hashContext) {
    return EmberObject.create({
      unknownProperty: function unknownProperty(key) {
        var fromHash = get(hashContext, key);
        return fromHash === undefined ? get(objectContext, key) : fromHash;
      }
    });
  }

  exports.default = Helper.extend({
    i18n: inject.service(),

    compute: function compute(_ref, interpolations) {
      var _ref2 = _slicedToArray(_ref, 2),
          key = _ref2[0],
          _ref2$ = _ref2[1],
          contextObject = _ref2$ === undefined ? {} : _ref2$;

      var mergedInterpolations = mergedContext(contextObject, interpolations);

      var i18n = get(this, 'i18n');
      return i18n.t(key, mergedInterpolations);
    },


    _recomputeOnLocaleChange: observer('i18n.locale', function () {
      this.recompute();
    })
  });
});