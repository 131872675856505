define("ember-composable-helpers/-private/closure-action", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var __loader = Ember.__loader;
  var ClosureActionModule = {
    ACTION: null
  };
  if ('ember-htmlbars/keywords/closure-action' in __loader.registry) {
    ClosureActionModule = __loader.require('ember-htmlbars/keywords/closure-action');
  } else if ('ember-routing-htmlbars/keywords/closure-action' in __loader.registry) {
    ClosureActionModule = __loader.require('ember-routing-htmlbars/keywords/closure-action');
  }
  var _default = _exports.default = ClosureActionModule.ACTION;
});