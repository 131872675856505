define("ember-macro-helpers/writable", ["exports", "ember-macro-helpers/computed"], function (_exports, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  function _default(getter, setterCallback) {
    var newCallback = {
      get: function get(val) {
        return val;
      }
    };
    if (setterCallback) {
      if (_typeof(setterCallback) === 'object' && setterCallback.set) {
        newCallback.set = setterCallback.set;
      } else {
        newCallback.set = function () {
          return setterCallback.apply(this, arguments);
        };
      }
    }
    return (0, _computed.default)(getter, newCallback);
  }
});