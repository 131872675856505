define("ember-composable-helpers/utils/includes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = includes;
  function includes(haystack) {
    var finder = haystack.includes || haystack.contains;
    for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      args[_key - 1] = arguments[_key];
    }
    return finder.apply(haystack, args);
  }
});